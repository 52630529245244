<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Menu Group</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              @click="addMenuGroup"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Menu Group
            </b-button>
            <b-modal
              id="modal-standard"
              :title="menu_group_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  :state="groupNameState"
                  label="Group Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id="name-input"
                    v-model="group_name"
                    :state="groupNameState"
                    class="input-style"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="form-group">
                  <label>Assign Kitchen</label>
                  <div>
                    <select
                      v-model="kitchen_id"
                      required
                      name="kitchen_id"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Kitchen</option>
                      <option
                        v-for="kitchen in kitchens"
                        :value="kitchen.id"
                        v-bind:key="kitchen.id"
                        :title="kitchen.kitchen_name"
                      >
                        {{ kitchen.kitchen_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="radio-btn-style">
                  <b-form-group label="POS Display" class="pb-0">
                    <div class="d-flex justify-content-start">
                      <b-form-radio
                        v-model="pos_dispay"
                        name="some-radios"
                        value="1"
                        >Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="pos_dispay"
                        name="some-radios"
                        value="0"
                        class="ml-3"
                        >No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter filter-dataTables text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="menu_group"
            :fields="menu_group_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="menu_group">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(menu_group)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuGroup(menu_group)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import axios from "axios";
import helpers from "../../helpers";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      // sortBy: "title",
      sortDesc: true,
      DropDownMenu: false,
      uuid: "",
      menu_group: [],
      kitchens: [],
      restaurantId: this.$route.params.restaurant,
      restaurantUuid: this.$route.params.restaurant_uuid,
      menu_group_fields: [
        { key: "group_name", label: "Group Name", sortable: true },
        { key: "kitchen.kitchen_name", label: "Assigned", sortable: true },
        { key: "action" }
      ],
      pos_dispay: "",
      kitchen_id: "",
      group_name: "",
      groupNameState: null,
      menu_group_title: "",
      menu_group_id: "",
      is_edit: false
    };
  },
  components: {},
  computed: {
    rows() {
      return this.menu_group.length;
    },
    csvData() {
      return this.menu_group.map(item => ({
        ...item
      }));
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.groupNameState = valid;
      return valid;
    },
    addMenuGroup() {
      this.group_name = "";
      this.pos_dispay = "";
      this.kitchen_id = "";
      this.groupNameState = null;
      this.is_edit = false;
      this.menu_group_title = "Add Menu Group";
    },
    resetModal() {},
    editModal(menu_category) {
      this.groupNameState = null;
      this.menu_group_id = menu_category.item.id;
      this.group_name = menu_category.item.group_name;
      this.pos_dispay = menu_category.item.is_pos_dispay;
      this.kitchen_id = menu_category.item.kitchen_id;
      this.is_edit = true;
      this.menu_group_title = "Edit Menu Group";
    },
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      let URL = "";
      let toastMessage = "";

      let formData = new FormData();
      if (this.is_edit) {
        URL = this.$path + "/admin/update/menu/group";
        toastMessage = "Menu Group Updated!";
        formData.append("menu_group_id", this.menu_group_id);
      } else {
        URL = this.$path + "/admin/add/menu/group/" + this.restaurantUuid;
        toastMessage = "Menu Group Added!";
      }
      formData.append("group_name", this.group_name);
      formData.append("pos_display", this.pos_dispay);
      formData.append("kitchen", this.kitchen_id);

      ApiService.post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          this.$bvToast.toast(toastMessage, {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.getRestaurantMenu();
          this.loader = false;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });

      // Prevent modal from closing
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    getRestaurantKitchens() {
      axios
        .get(this.$path + "/admin/restaurant/kitchen/" + this.restaurantUuid)
        .then(({ data }) => {
          this.kitchens = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurantMenu() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/restaurant/menu/" + this.restaurantUuid)
        .then(({ data }) => {
          this.menu_group = data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteMenuGroup(menu_group) {
      this.loader = true;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .get(
              this.$path + "/admin/delete/menu/group/" + menu_group.item.uuid
            )
            .then(() => {
              this.$bvToast.toast("Menu Group Delete Successfully!", {
                title: "Success",
                variant: "success",
                solid: true
              });
              this.getRestaurantMenu();
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "MenuGroup.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.menu_group);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "MenuGroup.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantMenu();
    this.getRestaurantKitchens();
    this.totalRows = this.menu_group.length;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
